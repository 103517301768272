import React from "react";
import {
  SEO,
  MarkdownContent,
  VideoPlayer,
  BasicCarousel,
  componentIterator,
  Image,
  DynamicForm,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  getComponentConfigurationData,
} from "../utils/utils";

export default class BridalStudioPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      rrybhacqrefitkjephygkktfandeanhpvurl,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.reginawebbsalon.com/bridal-studio/"}
        />
        <Grid
          className={"custom-page-bridal-studio custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"dtdbvshveptpruos"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"phasriviknxvlcut"}
              style={{ padding: 16 }}
              width={6}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "081f0905-5b3e-42bd-b8e1-80f4460e38f7",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"agapafozpwyzzijr"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"mmkddbqccssoyved"}
              style={{ padding: 16 }}
              width={14}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <VideoPlayer
                  className={"video-block"}
                  style={{}}
                  url={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "88c7ff46-b215-4d10-9e69-c36faf1a4f2b",
                    dataPath: "data.video.url",
                  })}
                  videoId={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "88c7ff46-b215-4d10-9e69-c36faf1a4f2b",
                    dataPath: "data.video.videoId",
                  })}
                  source={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "88c7ff46-b215-4d10-9e69-c36faf1a4f2b",
                    dataPath: "data.video.source",
                  })}
                  placeholder={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "88c7ff46-b215-4d10-9e69-c36faf1a4f2b",
                    dataPath: "data.video.placeholder",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ytbnaukwfmiaytop"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"yovlbkxunhwcwbon"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "d58ba25f-c112-4ece-8d5e-397fb905d2c8",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"lptoklmpoywztuty"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"yttlkvnqbmhuhoby"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <BasicCarousel
                  className={"image-block"}
                  style={{}}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "BasicCarousel",
                      componentIdentifier:
                        "cfb48d81-bc45-4e4c-848f-205b03a6ad45",
                    }),
                    component: <Image className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wluhhkjqexelyqce"}
            style={{ padding: "0", background: "#FFFFFF" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"dgwjuzviatofnwqv"}
              style={{ color: "#000000", padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "9bfa8fe6-1242-46a2-8bf1-7ff104e4248c",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"dddkprdppyhqdhzu"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ltolpfyxvdsoevpr"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={1491}
                  businessInfoId={6671}
                  websiteId={7742}
                  locationId={1562}
                  componentConfigurationId={74337}
                  header={rrybhacqrefitkjephygkktfandeanhpvurl.data.header}
                  fields={
                    rrybhacqrefitkjephygkktfandeanhpvurl.data.dynamicFormFields
                  }
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27334 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
        data {
          video {
            url
            source
            videoId
            placeholder
          }
        }
      }
    }
    rrybhacqrefitkjephygkktfandeanhpvurl: fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "6b6def93-9763-4cf0-baa3-3bc172ed8670" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
          sendEmailToSelectedLocation
        }
      }
    }
  }
`;
